import Flickity from 'flickity';
import { gsap } from "gsap";

/**
 * Navigation
 */

const navigation = document.getElementById('navigation');
const navigation_buttons = navigation.querySelectorAll('button');

// Smooth scroll to section on click in menu
if(navigation_buttons){
    navigation_buttons.forEach(button => {
        button.addEventListener('click', event => {
            const target_selector = event.target.dataset.target;
            const yOffset = -(16*8); 
            const element = document.getElementById(target_selector);
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            
            window.scrollTo({top: y, behavior: 'smooth'});
        });
    });
}

/**
 * Scroll to top
 */

function scrollToTop(element){
    window.scrollTo({top: 0, behavior: 'smooth'});
}

const scrollToTop_elements = document.querySelectorAll('[data-action="scroll-to-top"]');
if(scrollToTop_elements){
    scrollToTop_elements.forEach(element => {
        element.addEventListener('click', event => {
            scrollToTop(element); 
        });
    });
}

/**
 * Flickety Gallery
 */

const galleries = document.querySelectorAll('.gallery');

function initiateGallery(element){
    const flkty = new Flickity( element, {
        cellAlign: 'left',
        contain: true,
        prevNextButtons: false,
        pageDots: false,
        adaptiveHeight: true,
        wrapAround: true,
        lazyLoad: 2,
        on: {
            ready: function() {
                this.resize();
            },
            staticClick: function(event, pointer, cellElement, cellIndex){
                this.next();
            }
          }
      });      
}

// DOM READY
document.addEventListener('DOMContentLoaded', (e) => {
    // Gallery
    if(galleries){
        galleries.forEach(gallery => {
            initiateGallery(gallery);
        });
    }
});

/**
 * Material
 */

function animateMaterial(material){
    if(material.classList.contains('open')){
        gsap.to(material.querySelector('main'), {
            height: 'auto',
            duration: .5,
            ease: "power3.out"
        });
    }
    else{
        gsap.to(material.querySelector('main'), {
            height: '0',
            duration: .5,
            ease: "power3.out"
        });
    }
}

// Run tine

const materials = document.querySelectorAll('.material');

if(materials){
    materials.forEach(material => {
        const material_header = material.querySelector('header');
        material_header.addEventListener('click', event => {
            material.classList.toggle('open');
            animateMaterial(material);
        });
    });
}